.music_container {
    display: flex;
    flex-direction: column;
    padding: 2em;
    text-align: center;
    color: #FFFFFF;
}

.music_root {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}