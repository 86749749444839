.proj_entry_container {
    width: 20rem;
}

.proj_entry {
    display: grid;
    margin-top: 16px;
}

.proj_entry h3 {
    margin: 0.25rem;
    font-weight: bold;
    text-shadow: 0 0 8px slategray;
}

.proj_entry p {
    margin: 0.25rem;
}

.proj_entry img {
    max-width: 192px;
    border-radius: 12px;
    border: 3px solid slategray;

    opacity: 1;
    transition: .2s ease;
}

.proj_entry a:hover img {
    opacity: 0.5;
}

@media screen and (max-width: 830px) {
    .proj_entry_container {
        width: 100%;
    }
}