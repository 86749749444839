li {
    list-style-type: none;
}

.home  {
    width: 100%;
    flex: 0;
    margin-top: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.main_root {
    width: 100%;
}

.main_container {
    text-align: center;
}

.main_table {
    padding: 0;
    display: inline-flex;
    margin: auto;
}

.main_table li {
    margin: auto;
}

.main_table h1    {
    text-align: center;
    margin: 2em;
    color: white;
    font-size: 1em;
    font-weight: bold;
    text-shadow: 0 0 8px slategray;
}

.east_side {
    width: 256px;
    color: white;
}

.code_block p {
    margin: 0 0 0.4rem 0.75rem;
    text-align: left;
    font-family: Courier, serif;
    font-size: 0.75rem;
}

.divider {
    height: 1px;
    background-color: slategray;
    width: 80%;
    margin: 1.5rem auto;
}

.bread {
    color: white;
    text-align: center;
}

.bread h1 {
    margin: 0;
    font-weight: bold;
    text-shadow: 0 0 8px slategray;
}

#meitsi  {
    border-radius: 6px;
    border: 3px solid slategray;
    display: block;
    margin: auto;
    max-width: 256px;
}