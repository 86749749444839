#lang-button  {
  margin: 0;
  width: 2.6em;
}

#lang-button:hover  {
  cursor: pointer;
  opacity: 0.5;
}

.lang-container {
  display: inline;
  padding-left: 1em;
  padding-right: 1em;
}

.lang p {
  display: none;
  margin: auto;
  font-weight: normal;
  color: gray;
  font-size: 1.0em;
  text-align: center;
}

.lang {
  width: 40vw;
  text-align: center;
}
